import React from 'react'
import { useResumeTemplateEditStore } from '../../../store/resumetemplateEdit.store'
import { Box, FormControlLabel, MenuItem, Select, Switch, TextField, Typography } from '@mui/material'
import GroupSettings from './GroupSettings'
import { observer } from 'mobx-react'
import { displayPresets } from '../../../utils/resume-builder-utilities'

const ConfigureSelectedSection = observer( ( { newSectionDataGap, setNewSectionDataGap, newSectionPadding: paddingValue, setNewSectionPadding: setPaddingValue, newSectionGap: gapValue, setNewSectionGap: setGapValue } ) => {
    const TemplateStore = useResumeTemplateEditStore()

    const handleSectionPaddingChange = ( e ) => {
        let x = paddingValue.x
        let y = paddingValue.y
        x = x < 0 ? 0 : x
        y = y < 0 ? 0 : y
        setPaddingValue( { x, y } )
        changeSectionSettings( "padding", { x, y } )
    }

    const handleSectionGapChange = ( e ) => {
        let val = e.target.value
        val = val < 0 ? 0 : val
        setGapValue( val )
        changeSectionSettings( "gap", val )
    }

    const handleSectionDataGapChange = ( e ) => {
        let val = e.target.value
        val = val < 0 ? 0 : val
        setGapValue( val )
        changeSectionSettings( "dataSetGap", val )
    }

    const changeSectionSettings = ( key, val ) => {
        const newTemplate = { ...TemplateStore.getTemplate }
        newTemplate.rows[TemplateStore.selectedIndexes.row].columns[TemplateStore.selectedIndexes.column][TemplateStore.selectedIndexes.index][key] = val
        TemplateStore.updateTemplate( newTemplate )
    }

    return (
        <Box display="flex" flexDirection="column" gap="10px">
            <Typography color="secondary" padding="0 10px" variant="h6">
                {TemplateStore.getSelectedSection.section_title}
            </Typography>
            <Box border="1px solid #d3d3d3" padding="10px" display="flex" flexDirection="column" gap="10px">
                <Box borderLeft="none" borderRight="none" paddingRight="10px" display="flex">
                    <FormControlLabel sx={{ marginLeft: "0px", flexGrow: 1, justifyContent: "space-between" }} labelPlacement='start' control={<Switch size='small' checked={TemplateStore.getSelectedSection.show_section_title} onChange={e => changeSectionSettings( "show_section_title", e.target.checked )} />} label={<Typography variant="subtitle2" color="GrayText">Show section title</Typography>} />
                </Box>
                {TemplateStore.getSelectedSection.show_section_title && <Box>
                    <Typography fontSize="12px" variant="subtitle2" color="textSecondary">Select label style</Typography>
                    <Select fullWidth sx={{ fontSize: "14px" }} size='small' onChange={( e ) => { changeSectionSettings( "title_preset", e.target.value ) }} value={TemplateStore.getSelectedSection.title_preset ? TemplateStore.getSelectedSection.title_preset : "header2"}>
                        {Object.keys( displayPresets ).map( preset => (
                            <MenuItem key={preset} value={preset} sx={{ fontSize: `${displayPresets[preset].fontSize * TemplateStore.getTemplate.fontSize}px`, fontWeight: displayPresets[preset].fontWeight }}>{preset}</MenuItem>
                        ) )}
                    </Select>
                </Box>}
            </Box>
            <Box display="flex" gap="10px" padding="0 10px">
                <Box>
                    <Typography fontSize="12px" variant="subtitle2" color="textSecondary">PaddingX</Typography>
                    <TextField
                        onFocus={e => e.target.select()}
                        value={paddingValue ? paddingValue.x : 20}
                        onChange={( e ) => { setPaddingValue( { ...paddingValue, x: e.target.value } ) }}
                        sx={{ fontSize: "14px" }} InputProps={{ sx: { fontSize: "14px" } }} fullWidth
                        size='small'
                        type="number"
                        onKeyDown={e => { if ( e.key === "Enter" ) handleSectionPaddingChange( e ) }}
                        onBlur={e => handleSectionPaddingChange( e )
                        } />
                </Box>
                <Box>
                    <Typography fontSize="12px" variant="subtitle2" color="textSecondary">PaddingY</Typography>
                    <TextField
                        onFocus={e => e.target.select()}
                        value={paddingValue ? paddingValue.y : 20}
                        onChange={( e ) => { setPaddingValue( { ...paddingValue, y: e.target.value } ) }}
                        sx={{ fontSize: "14px" }} InputProps={{ sx: { fontSize: "14px" } }} fullWidth
                        size='small'
                        type="number"
                        onKeyDown={e => { if ( e.key === "Enter" ) handleSectionPaddingChange( e ) }}
                        onBlur={e => handleSectionPaddingChange( e )
                        } />
                </Box>
            </Box>
            <Box padding="0 10px">
                <Typography fontSize="12px" variant="subtitle2" color="textSecondary">Gap between attributes</Typography>
                <TextField
                    onFocus={e => e.target.select()}
                    value={gapValue}
                    onChange={( e ) => { setGapValue( e.target.value ) }}
                    sx={{ fontSize: "14px" }} InputProps={{ sx: { fontSize: "14px" } }} fullWidth
                    size='small'
                    type="number"
                    onKeyDown={e => { if ( e.key === "Enter" ) handleSectionGapChange( e ) }}
                    onBlur={e => handleSectionGapChange( e )
                    } />
            </Box>
            {TemplateStore.getSelectedSection.multiple && <Box padding="0 10px">
                <Typography fontSize="12px" variant="subtitle2" color="textSecondary">Gap between data sets</Typography>
                <TextField
                    onFocus={e => e.target.select()}
                    value={newSectionDataGap}
                    onChange={( e ) => { setNewSectionDataGap( e.target.value ) }}
                    sx={{ fontSize: "14px" }} InputProps={{ sx: { fontSize: "14px" } }} fullWidth
                    size='small'
                    type="number"
                    onKeyDown={e => { if ( e.key === "Enter" ) handleSectionDataGapChange( e ) }}
                    onBlur={e => handleSectionDataGapChange( e )
                    } />
            </Box>}
            <Box padding="0 10px">
                <Typography fontSize="12px" variant="subtitle2" color="textSecondary">Align</Typography>
                <Select fullWidth sx={{ fontSize: "14px" }} size='small' onChange={e => changeSectionSettings( "align", e.target.value )} value={TemplateStore.getSelectedSection.align ? TemplateStore.getSelectedSection.align : "flex-start"}>
                    <MenuItem value={"flex-start"} sx={{ fontSize: "14px" }}>Left</MenuItem>
                    <MenuItem value={"center"} sx={{ fontSize: "14px" }}>Center</MenuItem>
                    <MenuItem value={"flex-end"} sx={{ fontSize: "14px" }}>Right</MenuItem>
                </Select>
            </Box>
            <Box marginTop="10px">
                {TemplateStore.getSelectedSection.attributes.map( ( grp, grpIndex ) => (
                    <GroupSettings grpStyles={TemplateStore.getSelectedSection.groupStyles ? TemplateStore.getSelectedSection.groupStyles : {}} key={grpIndex} grp={grp} grpIndex={grpIndex} />
                ) )}
            </Box>
        </Box>
    )
} )

export default ConfigureSelectedSection
