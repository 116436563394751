import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Tooltip, Typography, useTheme } from '@mui/material'
import { convert } from 'html-to-text'
import React from 'react'
import { toast } from 'react-toastify'
// import { useQuizAndQuestionbankStore } from '../../store/quiz.store'
import { Icons, latexDelimiters } from '../../utils/utilities'
import Latex from 'react-latex-next'

const QuestionsPreviewDialog = ( { setReadOnlyModeForFileView = () => { }, setSelectedSection = () => { }, readOnly = false, file, setFile = () => { }, setQuestionFiles, fromComponent = "exam", section, questionsPreview, bankId, examId, setQuestionsPreview, setIsImporting, getQuestions, isImporting } ) => {

    // const QuizQuestionbankStore = useQuizAndQuestionbankStore()

    const { palette, border } = useTheme()

    const importQuestions = async () => {
        try {
            setIsImporting( true )
            setQuestionFiles( prev => [...prev, { id: Date.now(), section, file, questionCount: questionsPreview?.questions.length }] )
            setQuestionsPreview( false )
            setFile( null )
            setSelectedSection( null )
            setReadOnlyModeForFileView( false )
        } catch ( err ) {
            console.log( err )
            toast.error( err?.response?.data?.message || "Something went wrong while importing questions", { containerId: "main" } )
        } finally {
            setIsImporting( false )
        }
    }

    const handleClose = () => {
        setFile( null )
        setQuestionsPreview( false )
        setSelectedSection( null )
        setReadOnlyModeForFileView( false )
    }

    return (
        <Dialog open={questionsPreview ? true : false} PaperProps={{ sx: { minWidth: { md: "70vw", xs: "90%" } } }}>
            <DialogTitle sx={{ fontSize: "18px" }}>
                Imported file preview
            </DialogTitle>
            {questionsPreview?.status === "loaded" && <DialogContent>
                <Box border={border[1]} borderRadius="5px">
                    <Box>
                        {questionsPreview && Object.keys( questionsPreview?.errors || {} ).length > 0 && <Box bgcolor={`${palette.error.light}22`} padding="10px">
                            {Object.keys( questionsPreview?.errors || {} ).map( line => {
                                const error = questionsPreview?.errors[line]
                                return (
                                    <Box key={error.line}>
                                        <Typography color="error" variant="subtitle2">Line {error.line}</Typography>
                                        {error.messages.map( ( msg, i ) => (
                                            <Typography key={`key-${i}`} color="error" variant="subtitle2" marginLeft="10px">{msg}</Typography>
                                        ) )}
                                    </Box>
                                )
                            } )}
                        </Box>}
                        <Box padding="10px" marginTop="2px" bgcolor={`${palette.success.light}22`}>
                            <Typography color="success.main" variant='subtitle2'>{questionsPreview?.questions.length} questions detected</Typography>
                        </Box>
                        <Box margin="10px" borderRadius="5px" maxHeight="300px" overflow="auto" border="1px solid #d3d3d3">
                            {questionsPreview && questionsPreview.data.map( ( line, k ) => {
                                return (
                                    <Box key={`line-${k}`} position="relative" paddingLeft="35px" gap="20px" display="flex" height="100%" alignItems="center">
                                        <Box bgcolor={questionsPreview?.errorLines.includes( k + 1 ) ? "rgba(200,0,0,0.3)" : "#aaa"} borderRight="1px solid grey" fontSize="12px" color="white" display="flex" alignItems="center" justifyContent="center" position="absolute" left="0" height="100%" width="25px">{k + 1}</Box>
                                        <Tooltip title={"ERROR" || questionsPreview.errors[k + 1] ? questionsPreview.errors[k + 1]?.messages?.join( "," ) : ""} >
                                            <Box borderBottom={questionsPreview?.errorLines.includes( k + 1 ) ? "2px dashed rgba(200,0,0,0.5)" : "none"} sx={{ "& >p": { margin: "5px 0" } }} fontSize="12px !important">{convert( line ).trim() !== "" ? <Latex delimiters={latexDelimiters}>{`${line}`}</Latex> : <p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>}</Box>
                                        </Tooltip>
                                    </Box>
                                )
                            } )}
                        </Box>
                    </Box>
                    <Box borderTop="1px solid #d3d3d3" bgcolor={palette.greyedOut} padding="10px 20px" gap="20px" alignItems="center" display="flex">
                        {questionsPreview && !readOnly && <Button disableElevation disabled={Object.keys( questionsPreview?.errors || {} ).length > 0 || isImporting || !questionsPreview?.questions || questionsPreview?.questions?.length === 0} startIcon={isImporting ? <CircularProgress size={14} /> : Icons.default.DownloadForOfflineIcon} sx={{ textTransform: "capitalize" }} onClick={async () => { importQuestions( questionsPreview.questions ) }} variant="contained">{isImporting ? "Importing questions..." : "Import"}</Button>}
                        <Button disableElevation sx={{ textTransform: "capitalize" }} onClick={handleClose} color='error' variant="contained">Close</Button>
                    </Box>
                </Box>
            </DialogContent>}
            {questionsPreview?.status === "loading" && <DialogContent sx={{ padding: "0" }}>
                <Box display="flex" alignItems="center" justifyContent="center" height="200px" bgcolor="rgba(0,0,0,0.7)">
                    <Typography color="white" display="flex" alignItems="center" gap="10px"> <CircularProgress sx={{ color: "white" }} size={14} /> Extracting questions...</Typography>
                </Box>
            </DialogContent>}
        </Dialog>
    )
}

export default QuestionsPreviewDialog
