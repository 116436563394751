import { ErrorOutlineOutlined, WarningOutlined } from '@mui/icons-material'
import { Box, Button, Paper, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Icons } from '../../utils/utilities'

const typeAndColorMap = {
    error: {
        bgColor: "#dd2c00",
        dark: "#B02300",
        textColor: "#fff"
    },
    warning: {
        bgColor: "#ffd600",
        dark: "#E2BC00",
        textColor: "#000"
    }
}

function BlockMessage( { type = "error", message = "", dismissible = false, actions = [], blockTitle = "Error" } ) {

    const [dismissed, setDismissed] = useState( false )

    return (
        <Paper
            elevation={0}
            sx={{
                borderRadius: "5px",
                backgroundColor: typeAndColorMap[type].bgColor,
                display: dismissed ? "none" : "block",
                gap: "20px",
                alignItems: "center"
            }}>
            <Box padding="20px" borderBottom={`1px solid ${type === "error" ? "#d3d3d355" : "#0005"}`} display="flex" gap="20px" alignItems="center">
                {type === "error" && <ErrorOutlineOutlined sx={{ color: typeAndColorMap[type].textColor }} />}
                {type === "warning" && <WarningOutlined sx={{ color: typeAndColorMap[type].textColor }} />}
                <Typography variant='h6' sx={{ color: typeAndColorMap[type].textColor }} >{blockTitle}</Typography>
            </Box>
            <Box padding="20px">
                <Typography variant='body2' sx={{ color: typeAndColorMap[type].textColor }} >{message}</Typography>
            </Box>
            <Box padding="10px 20px" borderTop={`1px solid ${type === "error" ? "#d3d3d355" : "#0005"}`} display="flex" justifyContent="flex-start" flexWrap="wrap" alignItems="center">
                {dismissible && <Button startIcon={Icons.default.CancelIcon} onClick={() => setDismissed( true )} sx={{ textTransform: "capitalize", background: typeAndColorMap[type].dark + "99", color: typeAndColorMap[type].textColor, "&:hover": { background: typeAndColorMap[type].dark } }} >Dismiss</Button>}
                {actions.length > 0 && actions.map( action => (
                    <Button startIcon={action.icon} key={action.label} onClick={action.action} sx={{ textTransform: "capitalize", background: typeAndColorMap[type].dark + "99", color: typeAndColorMap[type].textColor, "&:hover": { background: typeAndColorMap[type].dark } }}>
                        {action.label}
                    </Button>
                ) )}
            </Box>
        </Paper>
    )
}

export default BlockMessage