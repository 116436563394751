import { CircularProgress, Typography } from '@mui/material'
import React from 'react'

const DataLoadingSpinner = ( { color = 'primary', waitingMessage, size, padding = "20px" } ) => {
    return (
        <Typography fontSize="14px" fontWeight="500" color="textSecondary" variant='caption' display="flex" padding={padding} gap="10px" alignItems="center"> <CircularProgress color={color} size={size || 16} /> {waitingMessage} </Typography>
    )
}

export default DataLoadingSpinner
