import { observable, action, toJS, computed, makeObservable } from 'mobx'
import api from '../service/api'
import AuthStore from './auth.store'
// import UserStore from "./user.store"
import React from 'react'

import NotificationStore from "./notification.store"
const notification = NotificationStore

class ApiProviderStore {

    apiprovider // Logged In Json Info

    constructor () {
        this.apiprovider = []
        this.api = api
        makeObservable( this, {
            apiprovider: observable,
            getApiProvider: computed,
            createApiProvider: action,
            fetchApiProviders: action,
            updateApiProvider: action,
            deleteApiProvider: action
        } )
    }

    // get compute_func()

    get getApiProvider() {
        return toJS( this.apiprovider )
    }

    createApiProvider = async ( payload ) => {
        //console.log(payload)
        try {
            const res = await this.api.createApiProvider( payload )
            console.log( res )
            this.fetchApiProviders()
            notification.setNotification( true, "Server added" )
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, "Failed to add server!" )
        }

    }

    fetchApiProviders = async () => {
        //console.log("payload")
        //console.log("data : ", UserStore.getUser["user_role"])
        if ( AuthStore.getToken === null || AuthStore.isTokenExpired() ) {
            console.log( "Invalid Request" )
        } else {

            // if ( UserStore.getUser["user_role"] === "admin" && UserStore.getUser["user_role"] !== "" && UserStore.getUser["user_role"] !== undefined ) {
            try {
                const res = await this.api.getApiProviders()
                //console.log(res)
                this.apiprovider = res["data"]["data"] ? res["data"]["data"].sort( ( a, b ) => ( a.api_provider > b.api_provider ) ? 1 : ( ( b.api_provider > a.api_provider ) ? -1 : 0 ) ) : []
                // this.apiprovider = this.apiprovider
                return res["data"]["data"] ? res["data"]["data"].sort( ( a, b ) => ( a.api_provider > b.api_provider ) ? 1 : ( ( b.api_provider > a.api_provider ) ? -1 : 0 ) ) : []

                //console.log(res["data"]["data"]
            } catch ( err ) {
                console.log( err )
            }
            // }

        }


    }

    updateApiProvider = async ( payload, api_id ) => {
        //console.log(payload)
        try {
            const res = await this.api.updateApiProvider( payload, api_id )
            console.log( res )
            await this.fetchApiProviders()
            //return res;
            notification.setNotification( true, "Server details updated" )
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, "Failed t0 updated server details" )
        }

    }

    deleteApiProvider = async ( api_id ) => {
        //console.log(payload)
        try {
            const res = await this.api.deleteApiProvider( api_id )
            console.log( res )
            this.fetchApiProviders()
            //return res;
            notification.setNotification( true, "Server deleted" )
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, err )
        }
    }


}

// decorate( ApiProviderStore, {
//     apiprovider: observable,
//     getApiProvider: computed,
//     createApiProvider: action,
//     fetchApiProviders: action,
//     updateApiProvider: action,
//     deleteApiProvider: action
// } )


const ApiProviderStoreInstance = new ApiProviderStore()

export default ApiProviderStoreInstance

const ApiProviderStoreContext = React.createContext()

export const ApiProviderStoreProvider = ( { children, store } ) => {
    return (
        <ApiProviderStoreContext.Provider value={store}>{children}</ApiProviderStoreContext.Provider>
    )
}

/* Hook to use store in any functional component */
export const useApiProviderStore = () => React.useContext( ApiProviderStoreContext )