import React from 'react'
import { TableRow, TableHead, TableCell, useTheme } from '@mui/material'

const TableHeader = ( { columns = [], fontSize } ) => {

    const { table } = useTheme()

    return (
        <TableHead>
            <TableRow>
                {columns.map( ( col, index ) => (
                    <TableCell key={index} align={col.align || 'left'} sx={{ fontSize, textTransform: "uppercase", background: table.headerBg, color: table.headerColor, padding: col.padding || "10px 20px", width: col.width, maxWidth: col.maxWidth, minWidth: col.minWidth, ...( col.styles || {} ) }}>{col.label || col.name}</TableCell>
                ) )}
            </TableRow>
        </TableHead>
    )
}

export default TableHeader
