import { Search } from '@mui/icons-material'
import { TextField, useTheme } from '@mui/material'
import React from 'react'

const CustomSearchBar = ( { icon = undefined, onKeyDown = () => { }, onKeyUp = () => { }, style = {}, border = "none", background, placeholder = "Search...", shadow = false, value = "", onChange = () => { }, width = "220px" } ) => {

    const { palette, theme } = useTheme()

    return (
        <TextField
            name='alivevideosearch'
            autoComplete='off'
            value={value}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onChange={onChange}
            InputProps={{
                disableUnderline: true,
                sx: {
                    outline: "none !important",
                    border: "none !important",
                    height: "40px",
                    background: background || theme === 'dark' ? palette.common.font : palette.common.bg,
                    fontSize: "14px",
                    color: palette.common.black
                },
                startAdornment: icon || <Search sx={{ marginX: "10px" }} />
            }}
            sx={{
                overflow: "hidden",
                width: width,
                boxShadow: shadow ? "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)" : "none",
                border: border,
                background: background || theme === 'dark' ? palette.common.font : palette.common.bg,
                marginRight: "10px",
                outline: "none",
                borderRadius: "5px",
                height: "40px",
                color: palette.common.bg,
                ...style,
                "@media(max-width:900px)": {
                    width: "100%"
                }
            }}
            placeholder={placeholder}
            variant="standard"
            type="text" />

    )
}

export default CustomSearchBar
