import React, { createContext, useEffect, useState } from 'react'

const ThemeContext = createContext()

const ThemeContextProvider = ( { children } ) => {

    const [theme, setTheme] = useState( 'light' )
    const [selectedTheme, setSelectedTheme] = useState( localStorage.getItem( 'preferedTheme' ) || 'light' )

    useEffect( () => {
        if ( selectedTheme === 'system' ) {
            if ( window.matchMedia && window.matchMedia( '(prefers-color-scheme: dark)' ).matches ) {
                setTheme( 'dark' )
            } else if ( window.matchMedia && window.matchMedia( '(prefers-color-scheme: light)' ).matches ) {
                setTheme( 'light' )
            } else {
                setTheme( 'light' )
            }
        } else
            setTheme( selectedTheme )
    }, [selectedTheme] )

    return <ThemeContext.Provider value={{ theme, setTheme, selectedTheme, setSelectedTheme }}>
        {children}
    </ThemeContext.Provider>
}

/* Hook to use store in any functional component */
export const useThemeContext = () => React.useContext( ThemeContext )

export default ThemeContextProvider