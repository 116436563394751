import { makeObservable, observable, runInAction, action, toJS, computed } from "mobx"
import React from "react"
import api from '../service/api'
import NotificationStore from "./notification.store"

const notification = NotificationStore

class ResumeBuilderStore {
    forms = []
    form = {}
    entities = []
    studentResumeInfo = {}
    template = {}
    templates = []

    constructor () {
        makeObservable( this, {
            forms: observable,
            form: observable,
            template: observable,
            templates: observable,
            studentResumeInfo: observable,
            entities: observable,
            fetchForms: action,
            createForm: action,
            updateForm: action,
            updateFormJson: action,
            fetchStudentForm: action,
            fetchStudentResumeInfo: action,
            updateStudentResumeInfo: action,
            editEntity: action,
            fetchForm: action,
            deleteForm: action,
            fetchEntities: action,
            createEntity: action,
            createTemplate: action,
            fetchTemplate: action,
            fetchTemplates: action,
            publishForm: action,
            getTemplate: computed,
            getTemplates: computed,
            getForms: computed,
            getForm: computed,
            getEntities: computed,
            getStudentResumeInfo: computed
        } )
    }

    async createForm( payload ) {
        try {
            const res = await api.createResumeForm( payload )
            return res.data
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
            return { success: false }
        }
    }

    async deleteForm( id ) {
        try {
            await api.deleteResumeForm( id )
            runInAction( () => {
                this.form = {}
            } )
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
        }
    }

    async fetchForms() {
        try {
            const { data } = await api.fetchResumeForms()
            runInAction( () => {
                this.forms = data.success ? data.data : []
            } )
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
        }
    }

    async fetchStudentForm() {
        try {
            const { data } = await api.fetchStudentForm()
            if ( data.success ) {
                runInAction( () => {
                    this.form = data.data
                } )
                return true
            } else {
                return false
            }
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
            return false
        }
    }

    async fetchStudentResumeInfo() {
        try {
            const { data } = await api.fetchStudentResumeInfo()
            runInAction( () => {
                if ( data.success )
                    this.studentResumeInfo = data.data
            } )
            return true
        } catch ( err ) {
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
            return false
        }
    }

    async updateStudentResumeInfo( formData ) {
        try {
            const { data } = await api.updateStudentResumeInfo( { studentInfo: formData } )
            runInAction( () => {
                if ( data.success )
                    this.studentResumeInfo = data.data
            } )
            return true
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
            return false
        }
    }

    async fetchForm( id ) {
        try {
            const { data } = await api.fetchResumeForm( id )
            if ( data.success && data.data ) {
                runInAction( () => {
                    this.form = data.data
                } )
                return true
            } else {
                return false
            }
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
            return false
        }
    }

    async updateFormJson( id, payload ) {
        try {
            const { data } = await api.updateResumeFormJson( id, payload )
            runInAction( () => {
                this.form = data.success ? data.data : this.form
            } )
            return true
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
        }
    }


    async updateForm( id, payload ) {
        try {
            const { data } = await api.updateResumeForm( id, payload )
            runInAction( () => {
                this.form = data.success ? data.data : this.form
            } )
            return true
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
            return false
        }
    }

    async publishForm() {
        try {
            const { data } = await api.publishResumeForm( this.form.id )
            if ( data.success ) {
                notification.setNotification( true, "Form successfully published" )
                runInAction( () => {
                    this.form = data.data
                } )
            }
            else
                notification.setNotification( true, data.message )
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
        }
    }

    async createEntity( payload ) {
        try {
            await api.createResumeEntity( payload )
            const toastMessage = <span>Successfully created entity <i style={{ color: "orange" }}>{payload.entityName}</i> </span>
            notification.setNotification( true, toastMessage )
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
        }
    }

    async fetchEntities() {
        try {
            const { data } = await api.fetchResumeEntities()
            runInAction( () => {
                this.entities = data.success ? data.data : []
            } )
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
        }
    }

    async editEntity( payload ) {
        try {
            await api.updateResumeEntity( payload )
            const toastMessage = <span>Successfully updated entity <i style={{ color: "orange" }}>{payload.entityName}</i> </span>
            notification.setNotification( true, toastMessage )
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
        }
    }

    // Templates

    async createTemplate( payload ) {
        try {
            const { data } = await api.createTemplate( payload )
            if ( data.success ) {
                runInAction( () => {
                    this.template = data.data
                } )
            }
            const toastMessage = <span>Successfully created new template </span>
            notification.setNotification( true, toastMessage )
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
        }
    }

    async fetchTemplates( institute = null ) {
        try {
            const { data } = await api.getTemplates( institute )
            runInAction( () => {
                this.templates = data.success ? data.data : []
            } )
            return true
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
            return false
        }
    }


    async fetchTemplate( id ) {
        try {
            const { data } = await api.getTemplate( id )
            runInAction( () => {
                this.template = data.success ? data.data : {}
            } )
            return true
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
            return false
        }
    }

    get getForms() {
        return toJS( this.forms )
    }

    get getForm() {
        return toJS( this.form )
    }

    get getStudentResumeInfo() {
        return toJS( this.studentResumeInfo )
    }

    get getEntities() {
        return toJS( this.entities )
    }

    get getTemplates() {
        return toJS( this.templates )
    }

    get getTemplate() {
        return toJS( this.template )
    }
}
const ResumeBuilderStoreInstance = new ResumeBuilderStore()

export default ResumeBuilderStoreInstance

const ResumeBuilderStoreContext = React.createContext()

export const ResumeBuilderStoreProvider = ( { children, store } ) => {
    return (
        <ResumeBuilderStoreContext.Provider value={store}>{children}</ResumeBuilderStoreContext.Provider>
    )
}

/* Hook to use store in any functional component */
export const useResumeBuilderStore = () => React.useContext( ResumeBuilderStoreContext )