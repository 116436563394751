import { observable, action, toJS, computed, makeObservable } from 'mobx'
import React from 'react'
import { toast } from 'react-toastify'

class NotificationStore {

    notification

    constructor () {
        this.notification = { "open": false, "message": "Hello" }
        makeObservable( this, {
            notification: observable,
            getNotification: computed,
            setNotification: action,
            handleClose: action
        } )
    }

    get getNotification() {
        return toJS( this.notification )
    }

    setNotification = ( status, message, autoClose = 3000 ) => {
        toast( message, { autoClose } )
        this.notification = { "open": status, "message": message }
    }

    handleClose = () => {
        // this.notification = {"open":false,"message": ""}
        this.setNotification( false, "" )
    }

}

// decorate(NotificationStore, {
//     notification:observable,
//     getNotification:computed,
//     setNotification:action,
//     handleClose:action
// })

const NotificationStoreInstance = new NotificationStore()

export default NotificationStoreInstance

const NotificationStoreContext = React.createContext()

export const NotificationStoreProvider = ( { children, store } ) => {
    return (
        <NotificationStoreContext.Provider value={store}>{children}</NotificationStoreContext.Provider>
    )
}

/* Hook to use store in any functional component */
export const useNotificationStore = () => React.useContext( NotificationStoreContext )