import { useState } from "react"
import { useResumeTemplateEditStore } from "../../../store/resumetemplateEdit.store"
import { Box, IconButton, MenuItem, Select, Typography, TextField, Checkbox, Tooltip } from "@mui/material"
import { FormatAlignJustify, FormatItalic, FormatUnderlined, KeyboardArrowDown, Visibility, VisibilityOff } from "@mui/icons-material"
import { displayPresets, fontWeights, templateColorThemes } from "../../../utils/resume-builder-utilities"
import { observer } from "mobx-react"
import { useEffect } from "react"

const AttributeSettings = observer( ( { attr, grpIndex, attrIndex } ) => {

    const [open, setOpen] = useState( false )
    const [fontSizeValue, setFontSizeValue] = useState( null )
    const [fileSizeValue, setFileSizeValue] = useState( null )
    const TemplateStore = useResumeTemplateEditStore()

    const changeSectionAttrData = ( grpIndex, attrIndex, key, val ) => {
        try {
            const newTemplate = { ...TemplateStore.getTemplate }
            const section = newTemplate.rows[TemplateStore.selectedIndexes.row].columns[TemplateStore.selectedIndexes.column][TemplateStore.selectedIndexes.index]
            const attr = section.attributes[grpIndex][attrIndex]
            attr[key] = val
            TemplateStore.updateTemplate( newTemplate )
        } catch ( err ) {

        }
    }

    const handleGroupFontWeightChange = ( e, grpIndex, attrIndex ) => {
        changeSectionAttrData( grpIndex, attrIndex, "fontWeight", e.target.value )
        changeSectionAttrData( grpIndex, attrIndex, "displayAs", "custom" )
    }

    const handleGroupPresetChange = ( e, grpIndex, attrIndex ) => {
        changeSectionAttrData( grpIndex, attrIndex, "displayAs", e.target.value )
        changeSectionAttrData( grpIndex, attrIndex, "fontSize", displayPresets[e.target.value].fontSize * TemplateStore.getTemplate.fontSize )
        setFontSizeValue( displayPresets[e.target.value].fontSize * TemplateStore.getTemplate.fontSize )
        changeSectionAttrData( grpIndex, attrIndex, "fontWeight", displayPresets[e.target.value].fontWeight )
    }

    const handleGroupFontSizeChange = ( e, grpIndex, attrIndex ) => {
        let val = e.target.value
        val = val < 8 ? 8 : val
        setFontSizeValue( val )
        changeSectionAttrData( grpIndex, attrIndex, "fontSize", val )
        changeSectionAttrData( grpIndex, attrIndex, "displayAs", "custom" )
    }

    const handleGroupFileSizeChange = ( e, grpIndex, attrIndex ) => {
        let val = e.target.value
        val = val < 120 ? 120 : val
        setFileSizeValue( val )
        changeSectionAttrData( grpIndex, attrIndex, "size", val )
    }

    useEffect( () => {
        return () => {
            setOpen( false )
            setFileSizeValue( null )
            setFontSizeValue( null )
        }
    }, [] )

    return <Box border="1px solid #d3d3d3" padding="5px" key={attrIndex}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography flexGrow={1} onClick={() => setOpen( !open )} variant="subtitle2" gutterBottom>{attr.entity_name}
            </Typography>
            <Box display="flex" alignItems="center">
                <IconButton size="small" onClick={() => {
                    changeSectionAttrData( grpIndex, attrIndex, "visibility", attr.visibility === undefined || attr.visibility === true ? false : attr.visibility ? false : true )
                }}>
                    {( attr.visibility === undefined ) ? <Visibility fontSize='14px' /> : attr.visibility ? <Visibility fontSize='14px' /> : <VisibilityOff fontSize='14px' />}
                </IconButton>
                <KeyboardArrowDown onClick={() => setOpen( !open )} sx={{ fontSize: "16px" }} />
            </Box>
        </Box>
        <Box display={open ? "flex" : "none"} flexDirection="column" gap="5px">
            {attr.entity_detail.label_show &&
                <Box display="flex" flexDirection="column" gap="5px">
                    <Box>
                        <Typography fontSize="12px" variant="subtitle2" color="textSecondary">Select label style</Typography>
                        <Select fullWidth disabled={!( attr.visibility === undefined || attr.visibility === true )} sx={{ fontSize: "14px" }} size='small' onChange={( e ) => { changeSectionAttrData( grpIndex, attrIndex, "labelFontSize", e.target.value ) }} value={attr.labelFontSize ? attr.labelFontSize : "custom"}>
                            {Object.keys( displayPresets ).map( preset => (
                                <MenuItem key={preset} value={preset} sx={{ fontSize: `${displayPresets[preset].fontSize * TemplateStore.getTemplate.fontSize}px`, fontWeight: displayPresets[preset].fontWeight }}>{preset}</MenuItem>
                            ) )}
                        </Select>
                    </Box>
                    <Box padding="5px 0" gap="5px">
                        <Typography fontSize="12px" variant="subtitle2" color="textSecondary">Label color</Typography>
                        <Box display="flex" flexWrap="wrap" gap="10px">
                            {Object.keys( templateColorThemes[TemplateStore.getTemplate.colorTheme] ).map( color => (
                                <Tooltip onClick={() => changeSectionAttrData( grpIndex, attrIndex, "labelColor", color )} sx={{ cursor: "pointer", border: color === attr.labelColor ? "2px solid grey" : "2px solid white" }} title={color} key={color}>
                                    <Box display="flex" alignItems="center" justifyContent="center" borderRadius="50%" width="20px" height="20px">
                                        <Box bgcolor={templateColorThemes[TemplateStore.getTemplate.colorTheme][color]} borderRadius="50%" height="100%" width="100%"></Box>
                                    </Box>
                                </Tooltip>
                            ) )}
                        </Box>
                    </Box>
                </Box>
            }
            <Box borderTop="1px solid #d3d3d3" padding="5px 0" gap="5px">
                <Typography fontSize="12px" variant="subtitle2" color="textSecondary">Value color</Typography>
                <Box display="flex" flexWrap="wrap" gap="10px">
                    {Object.keys( templateColorThemes[TemplateStore.getTemplate.colorTheme] ).map( color => (
                        <Tooltip onClick={() => changeSectionAttrData( grpIndex, attrIndex, "color", color )} sx={{ cursor: "pointer", border: color === attr.color ? "2px solid black" : "2px solid white" }} title={color} key={color}>
                            <Box display="flex" alignItems="center" justifyContent="center" borderRadius="50%" width="20px" height="20px">
                                <Box bgcolor={templateColorThemes[TemplateStore.getTemplate.colorTheme][color]} borderRadius="50%" height="100%" width="100%"></Box>
                            </Box>
                        </Tooltip>
                    ) )}
                </Box>
            </Box>
            {attr.entity_detail.data_type !== "file" && <Box display="flex" flexDirection="column" gap="5px">
                <Box display="flex" paddingRight="10px" gap="5px">
                    <Checkbox disableFocusRipple disableRipple disableTouchRipple sx={{ background: attr.italic ? "#eee" : "none", borderRadius: "5px", padding: "5px" }} icon={<FormatItalic sx={{ fontSize: "18px" }} />} checkedIcon={<FormatItalic sx={{ fontSize: "18px" }} />} checked={attr.italic ? attr.italic : false} onChange={e => changeSectionAttrData( grpIndex, attrIndex, "italic", e.target.checked )} />
                    <Checkbox disableFocusRipple disableRipple disableTouchRipple sx={{ background: attr.underline ? "#eee" : "none", borderRadius: "5px", padding: "5px" }} icon={<FormatUnderlined sx={{ fontSize: "18px" }} />} checkedIcon={<FormatUnderlined sx={{ fontSize: "18px" }} />} checked={attr.underline ? attr.underline : false} onChange={e => changeSectionAttrData( grpIndex, attrIndex, "underline", e.target.checked )} />
                    <Checkbox disableFocusRipple disableRipple disableTouchRipple sx={{ background: attr.justify ? "#eee" : "none", borderRadius: "5px", padding: "5px" }} icon={<FormatAlignJustify sx={{ fontSize: "18px" }} />} checkedIcon={<FormatAlignJustify sx={{ fontSize: "18px" }} />} checked={attr.justify ? attr.justify : false} onChange={e => changeSectionAttrData( grpIndex, attrIndex, "justify", e.target.checked )} />
                </Box>
                {attr.entity_detail.data_type !== "array" && <Box>
                    <Typography fontSize="12px" variant="subtitle2" color="textSecondary">Display value as</Typography>
                    <Select disabled={!( attr.visibility === undefined || attr.visibility === true )} sx={{ fontSize: "14px" }} fullWidth size='small' onChange={( e ) => handleGroupPresetChange( e, grpIndex, attrIndex )} value={attr.displayAs ? attr.displayAs : "custom"}>
                        {Object.keys( displayPresets ).map( preset => (
                            <MenuItem key={preset} value={preset} sx={{ fontSize: `${displayPresets[preset].fontSize * TemplateStore.getTemplate.fontSize}px`, fontWeight: displayPresets[preset].fontWeight }}>{preset}</MenuItem>
                        ) )}
                    </Select>
                </Box>}

                {attr.entity_detail.data_type === "array" && <Box>
                    <Typography fontSize="12px" variant="subtitle2" color="textSecondary">Display values as</Typography>
                    <Select disabled={!( attr.visibility === undefined || attr.visibility === true )} sx={{ fontSize: "14px" }} fullWidth size='small' onChange={( e ) => changeSectionAttrData( grpIndex, attrIndex, "displayItemAs", e.target.value )} value={attr.displayItemAs ? attr.displayItemAs : "roundedRectangleChip"}>
                        <MenuItem value="roundedRectangleChip" dense sx={{ fontSize: "14px" }}>Rouded rectangular chip</MenuItem>
                        <MenuItem value="retangleChip" dense sx={{ fontSize: "14px" }}>Rectangular chip</MenuItem>
                        <MenuItem value="list" dense sx={{ fontSize: "14px" }}>List</MenuItem>
                    </Select>
                </Box>
                }
                <Box>
                    <Typography fontSize="12px" variant="subtitle2" color="textSecondary">Select font size</Typography>
                    <TextField
                        onFocus={e => e.target.select()}
                        onChange={e => setFontSizeValue( e.target.value )}
                        disabled={!( attr.visibility === undefined || attr.visibility === true )}
                        sx={{ fontSize: "14px" }} InputProps={{ sx: { fontSize: "14px" } }}
                        fullWidth size='small' type="number"
                        onBlur={e => handleGroupFontSizeChange( e, grpIndex, attrIndex )}
                        onKeyDown={e => { if ( e.key === "Enter" ) handleGroupFontSizeChange( e, grpIndex, attrIndex ) }}
                        value={fontSizeValue ? fontSizeValue : attr.fontSize ? attr.fontSize : 16} />
                </Box>
                <Box>
                    <Typography fontSize="12px" variant="subtitle2" color="textSecondary">Select font weight</Typography>
                    <Select disabled={!( attr.visibility === undefined || attr.visibility === true )} sx={{ fontSize: "14px" }} fullWidth size='small' onChange={e => handleGroupFontWeightChange( e, grpIndex, attrIndex )} value={attr.fontWeight ? attr.fontWeight : "400"}>
                        {fontWeights.map( weight => (
                            <MenuItem dense key={weight.val} sx={{ fontWeight: weight.val, fontSize: "14px" }} value={weight.val} >{weight.label}</MenuItem>
                        ) )}
                    </Select>
                </Box>
                <Box>
                    <Typography fontSize="12px" variant="subtitle2" color="textSecondary">Text Transform</Typography>
                    <Select disabled={!( attr.visibility === undefined || attr.visibility === true )} sx={{ fontSize: "14px" }} fullWidth size='small' onChange={( e ) => {
                        changeSectionAttrData( grpIndex, attrIndex, "textTransform", e.target.value )
                    }} value={attr.textTransform ? attr.textTransform : "none"}>
                        <MenuItem dense sx={{ fontSize: "14px" }} value="none" >None</MenuItem>
                        <MenuItem dense sx={{ fontSize: "14px" }} value="capitalize" >Capitalize</MenuItem>
                        <MenuItem dense sx={{ fontSize: "14px" }} value="uppercase" >Uppercase</MenuItem>
                        <MenuItem dense sx={{ fontSize: "14px" }} value="lowercase" >Lowecase</MenuItem>
                    </Select>
                </Box>
            </Box>}
            {attr.entity_detail.data_type === "file" && <Box>
                <Box>
                    <Typography fontSize="12px" variant="subtitle2" color="textSecondary">Select size</Typography>
                    <TextField
                        onFocus={e => e.target.select()}
                        onChange={e => setFileSizeValue( e.target.value )}
                        disabled={!( attr.visibility === undefined || attr.visibility === true )}
                        sx={{ fontSize: "14px" }} InputProps={{ sx: { fontSize: "14px" } }}
                        fullWidth size='small' type="number"
                        onBlur={e => handleGroupFileSizeChange( e, grpIndex, attrIndex )}
                        onKeyDown={e => { if ( e.key === "Enter" ) handleGroupFileSizeChange( e, grpIndex, attrIndex ) }}
                        value={fileSizeValue ? fileSizeValue : attr.size ? attr.size : 150} />
                </Box>
            </Box>}
        </Box>
    </Box>
} )


const GroupSettings = observer( ( { grpIndex, grp, grpStyles } ) => {

    const TemplateStore = useResumeTemplateEditStore()

    const handleGroupStyleChange = ( grpIndex, key, val ) => {
        const newTemplate = { ...TemplateStore.getTemplate }
        const section = newTemplate.rows[TemplateStore.selectedIndexes.row].columns[TemplateStore.selectedIndexes.column][TemplateStore.selectedIndexes.index]
        if ( section.groupStyles ) {
            if ( section.groupStyles[grpIndex] ) {
                section.groupStyles[grpIndex][key] = val
            } else {
                section.groupStyles[grpIndex] = {}
                section.groupStyles[grpIndex][key] = val
            }
        } else {
            section.groupStyles = {}
            section.groupStyles[grpIndex] = {}
            section.groupStyles[grpIndex][key] = val
        }
        TemplateStore.updateTemplate( newTemplate )
    }

    return <Box>
        <Box display="flex" padding={grp.length > 1 ? "10px" : "0"} border={grp.length > 1 ? "1px solid #d3d3d3" : "none"} marginY={grp.length > 1 ? "10px" : "0"} flexDirection="column" gap="5px">
            {grp.length > 1 && <Box marginBottom="10px">
                <Typography fontSize="10px" fontWeight="600" color="GrayText">Group</Typography>
                <Box>
                    <Typography fontSize="12px" variant="subtitle2" color="textSecondary">Spacing</Typography>
                    <Select sx={{ fontSize: "14px" }} fullWidth size='small' onChange={( e ) => handleGroupStyleChange( grpIndex, "spacing", e.target.value )} value={grpStyles[grpIndex]?.spacing ? grpStyles[grpIndex].spacing : "gap"}>
                        <MenuItem value={"gap"} sx={{ fontSize: "14px" }}>Gap</MenuItem>
                        <MenuItem value={"space-between"} sx={{ fontSize: "14px" }}>Space in between</MenuItem>
                    </Select>
                </Box>
                <Box>
                    <Typography fontSize="12px" variant="subtitle2" color="textSecondary">Separator</Typography>
                    <Select sx={{ fontSize: "14px" }} fullWidth size='small' onChange={( e ) => handleGroupStyleChange( grpIndex, "separator", e.target.value )} value={grpStyles[grpIndex]?.separator ? grpStyles[grpIndex].separator : "  "}>
                        <MenuItem value={"  "} sx={{ fontSize: "14px" }}>White space</MenuItem>
                        <MenuItem value={"-"} sx={{ fontSize: "14px" }}>Dash (-)</MenuItem>
                        <MenuItem value={"|"} sx={{ fontSize: "14px" }}>Vertical line (|)</MenuItem>
                    </Select>
                </Box>
            </Box>}
            {grp.map( ( attr, attrIndex ) => {
                return (
                    <AttributeSettings attr={attr} attrIndex={attrIndex} grpIndex={grpIndex} />
                )
            } )}
        </Box>
    </Box>
} )

export default GroupSettings