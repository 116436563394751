import { makeObservable, observable, runInAction, action, toJS, computed } from "mobx"
import React from "react"
import api from '../service/api'
import NotificationStore from "./notification.store"

const notification = NotificationStore

class ResumeTemplateEditStore {
    template = {}
    templateData = {}
    selectedSection = null
    selectedIndexes = null
    selectedRowIndex = null
    templateId = null
    previewMode = false
    saveData = { status: "saved", lastSaved: null }
    selectedTheme = {
        black: "#000",
        primary: "#273679",
        secondary: "#f18f08",
        lightGrey: "#eee",
        darkGrey: "grey"
    }

    constructor () {
        makeObservable( this, {
            template: observable,
            templateId: observable,
            selectedTheme: observable,
            previewMode: observable,
            selectedSection: observable,
            selectedIndexes: observable,
            selectedRowIndex: observable,
            templateData: observable,
            saveData: observable,
            setSelectedRowIndex: action,
            fetchTemplate: action,
            setPreviewMode: action,
            setTemplate: action,
            setSelectedSection: action,
            getTemplate: computed,
            getSelectedRowIndex: computed,
            getSelectedSection: computed,
            getPreviewMode: computed,
            getSavingDetails: computed,
            getTemplateData: computed
        } )
    }

    async updateTemplate( template ) {
        try {
            runInAction( () => {
                this.saveData = { status: "Saving", lastSaved: null }
            } )
            this.setTemplate( template )
            await api.updateTemplate( this.getTemplateData.id, { template_json: template } )
            runInAction( () => {
                this.saveData = { status: "Saved", lastSaved: new Date() }
            } )

            return true
        } catch ( err ) {
            runInAction( () => {
                this.saveData = { status: "Saved", lastSaved: new Date(), error: true }
            } )
            return false
        }
    }

    setSelectedRowIndex( rowIndex ) {
        this.selectedIndexes = null
        this.selectedSection = null
        this.selectedRowIndex = rowIndex
    }

    setTemplate( template ) {
        runInAction( () => {
            this.template = template
        } )
        if ( this.selectedSection )
            this.setSelectedSection( this.selectedIndexes )
    }

    setSelectedSection( { row, column, index } ) {
        if ( this.template.rows && this.template.rows[row] && this.template.rows[row]?.columns && this.template.rows[row]?.columns[column] )
            runInAction( () => {
                this.selectedRowIndex = null
                this.selectedIndexes = { row, column, index }
                this.selectedSection = this.template.rows[row].columns[column][index]
            } )
    }

    removeSectionFromColumn( { row, column, index } ) {
        if ( this.template.rows && this.template.rows[row] && this.template.rows[row]?.columns && this.template.rows[row]?.columns[column] ) {
            const newTemplate = { ...this.template }
            newTemplate.rows[row].columns[column].splice( index, 1 )
            if ( this.selectedIndexes && this.selectedIndexes.row === row && this.selectedIndexes.column === column && this.selectedIndexes.index === index ) {
                runInAction( () => {
                    this.selectedIndexes = null
                    this.selectedSection = null
                } )
            }
            runInAction( () => {
                this.template = newTemplate
            } )
        }
    }

    setPreviewMode( val ) {
        runInAction( () => {
            this.previewMode = val
            this.selectedIndexes = null
            this.selectedRowIndex = null
            this.selectedSection = null
        } )
    }

    async createTemplate( payload ) {
        try {
            await api.createTemplate( payload )
            const toastMessage = <span>Successfully created new template </span>
            notification.setNotification( true, toastMessage )
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
        }
    }

    async fetchTemplate( id ) {
        try {
            const { data } = await api.getTemplate( id )
            if ( data.success && data.data ) {
                runInAction( () => {
                    this.templateId = id
                    this.templateData = data.data
                    runInAction( () => {
                        this.saveData = { status: "saved", last_saved: this.templateData.last_updated }
                    } )
                } )
                return true
            } else {
                return false
            }
        } catch ( err ) {
            console.log( err )
            notification.setNotification( true, ( err.response && err.response.data ) ? err.response.data.message : "Something went wrong!" )
            return false
        }
    }

    get getTemplate() {
        return toJS( this.template )
    }

    get getTemplateData() {
        return toJS( this.templateData )
    }

    get getSelectedRowIndex() {
        return toJS( this.selectedRowIndex )
    }

    get getSelectedSection() {
        return toJS( this.selectedSection )
    }

    get getSelectedTheme() {
        return toJS( this.selectedTheme )
    }

    get getSelectedIndexes() {
        return toJS( this.selectedIndexes )
    }

    get getPreviewMode() {
        return toJS( this.previewMode )
    }

    get getSavingDetails() {
        return toJS( this.saveData )
    }
}
const ResumeTemplateEditStoreInstance = new ResumeTemplateEditStore()

export default ResumeTemplateEditStoreInstance

const ResumeTemplateEditStoreContext = React.createContext()

export const ResumeTemplateEditStoreProvider = ( { children, store } ) => {
    return (
        <ResumeTemplateEditStoreContext.Provider value={store}>{children}</ResumeTemplateEditStoreContext.Provider>
    )
}

/* Hook to use store in any functional component */
export const useResumeTemplateEditStore = () => React.useContext( ResumeTemplateEditStoreContext )