import React from 'react'
import { observable, action, toJS, computed, makeObservable, runInAction } from 'mobx'
import api from '../service/api'
import AuthStore from './auth.store'

class OrganizationStore {

    organization
    department
    constructor () {
        this.organization = []
        this.department = []
        this.api = api
        makeObservable( this, {
            organization: observable,
            department: observable,
            getOrganization: computed,
            getDepartment: computed,
            createOrganization: action,
            createDepartment: action,
            fetchOrganization: action,
            fetchDepartment: action,
            updateOrganization: action,
            updateDepartment: action,
            deleteOrganization: action,
            deleteDepartment: action
        } )
    }

    get getOrganization() {
        return toJS( this.organization )
    }
    get getDepartment() {
        return toJS( this.department )
    }

    createOrganization = async ( payload ) => {
        try {
            const res = await this.api.createOrganization( payload )
            console.log( res )
            this.fetchOrganization()
        } catch ( err ) {
            console.log( err )
        }
    }

    createDepartment = async ( payload ) => {
        try {
            const res = await this.api.createDepartment( payload )
            console.log( res )
            this.fetchOrganization()
        } catch ( err ) {
            console.log( err )
        }
    }

    fetchOrganization = async () => {
        if ( AuthStore.getToken === null || AuthStore.isTokenExpired() ) {
            //console.log("Invalid Request")
            try {
                const res = await this.api.getAllOrganization()
                //console.log(res)
                runInAction( () => {
                    this.organization = res["data"]["data"] ? res["data"]["data"] : []
                } )
                //return res;
            } catch ( err ) {
                console.log( err )
            }
        } else {
            try {
                const res = await this.api.getOrganization()
                //console.log(res)
                runInAction( () => {
                    this.organization = res["data"]["data"] ? res["data"]["data"] : []
                } )
                //return res;
            } catch ( err ) {
                console.log( err )
            }
        }
    }

    fetchDepartment = async () => {
        try {
            if ( AuthStore.getToken === null || AuthStore.isTokenExpired() ) {
                //console.log("Invalid Request")
                const res = await this.api.getAllDepartment()

                console.log( res )
                this.department = res["data"]["data"] ? res["data"]["data"] : []
                //return res;
            } else {
                const res = await this.api.getDepartment()
                console.log( res )
                this.department = res["data"]["data"] ? res["data"]["data"] : []
                //return res;
            }
        } catch ( err ) {
            console.log( err )
        }
    }

    updateOrganization = async ( payload ) => {
        //console.log(payload)
        try {
            const res = this.api.updateOrganization( payload )
            console.log( res )
            this.fetchOrganization()
            //return res;
        } catch ( err ) {
            console.log( err )
        }
    }

    updateDepartment = async ( payload ) => {
        //console.log(payload)
        try {
            const res = await this.api.updateDepartment( payload )
            console.log( res )
            this.fetchDepartment()
            //return res;
        } catch ( err ) {
            console.log( err )
        }
    }

    deleteOrganization = async ( payload ) => {
        //console.log(payload)
        try {
            const res = await this.api.deleteOrganization( payload )
            console.log( res )
            this.fetchDepartment()
            //return res;
        } catch ( err ) {
            console.log( err )
        }
    }

    deleteDepartment = async ( id ) => {
        //console.log(payload)
        try {
            const res = await this.api.deleteDepartment( id )
            console.log( res )
            this.fetchDepartment()
            //return res;
        } catch ( err ) {
            console.log( err )
        }
    }
}

// decorate(OrganizationStore, {
//     organization:observable,
//     department:observable,
//     getOrganization:computed,
//     getDepartment:computed,
//     createOrganization:action,
//     createDepartment:action,
//     fetchOrganization:action,
//     fetchDepartment:action,
//     updateOrganization:action,
//     updateDepartment:action,
//     deleteOrganization:action,
//     deleteDepartment:action
// })

const OrganizationStoreInstance = new OrganizationStore()

export default OrganizationStoreInstance

const OrganizationStoreContext = React.createContext()

export const OrganizationStoreProvider = ( { children, store } ) => {
    return (
        <OrganizationStoreContext.Provider value={store}>{children}</OrganizationStoreContext.Provider>
    )
}

/* Hook to use store in any functional component */
export const useOrganizationStore = () => React.useContext( OrganizationStoreContext )