import { observable, action, makeObservable, toJS, computed } from 'mobx'
import api from '../service/api'
import React from 'react'

class AnalyticsStore {

    analytics
    nodes

    constructor () {
        this.analytics = ["Analytics"]
        this.nodes = []
        this.api = api
        makeObservable( this, {
            analytics: observable,
            nodes: observable,
            getNodes: computed,
            fetchNodes: action,
            fetchServerStat: action
        } )
        //this.fetchNodes();
    }

    // nodes api information
    get getNodes() {
        return toJS( this.nodes )
    }

    //load all the nodes
    fetchNodes = async () => {
        try {
            const res = await this.api.getNodes()
            let newnodes = res["data"]["data"]
            this.nodes = newnodes ? newnodes.map( v => ( { ...v, load: 0 } ) ) : []
        } catch ( err ) {

        }
    }

    fetchServerStat = async () => {
        return await this.api.getServerStat()
    }
}

const AnalyticsStoreInstance = new AnalyticsStore()

export default AnalyticsStoreInstance

const AnalyticsStoreContext = React.createContext()

export const AnalyticsStoreProvider = ( { children, store } ) => {
    return (
        <AnalyticsStoreContext.Provider value={store}>{children}</AnalyticsStoreContext.Provider>
    )
}

/* Hook to use store in any functional component */
export const useAnalyticsStore = () => React.useContext( AnalyticsStoreContext )