import { Switch, styled } from "@mui/material"

const CustomSwitch = styled( Switch )( ( { theme } ) => ( {
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.primary.contrastText,
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M11.4379 4.83825L4.83823 11.4379L3.89542 10.4951L10.4951 3.89544L11.4379 4.83825Z" fill="${encodeURIComponent(
                theme.palette.error.contrastText,
            )}"/><path d="M10.4951 11.4379L3.89542 4.83824L4.83823 3.89543L11.4379 10.4951L10.4951 11.4379Z" fill="${encodeURIComponent( theme.palette.error.contrastText )}" /></svg>')`,
            right: 12
        }
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
} ) )

export default CustomSwitch


// backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
//     theme.palette.getContrastText( theme.palette.primary.main ),
// )}" d="M19,13H5V11H19V13Z" /></svg>')`,
// < path d="M10.4951 11.4379L3.89542 4.83824L4.83823 3.89543L11.4379 10.4951L10.4951 11.4379Z" fill="${encodeURIComponent( theme.palette.getContrastText( theme.palette.error.main ) )}" />