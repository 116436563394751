import { Box, Button, Dialog, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useResumeBuilderStore } from '../../../store/resumebuilder.store'
import * as Yup from 'yup'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useNavigate } from 'react-router-dom'

const AddResumeTemplateDialog = ( { state, setAddDialogState } ) => {

    const [resumeForms, setResumeForms] = useState( [] )
    const [loading, setLoading] = useState( true )
    const ResumeBuilderStore = useResumeBuilderStore()
    const navigate = useNavigate()

    const initialValues = {
        name: '',
        description: '',
        formId: ''
    }

    const validationSchema = Yup.object().shape( {
        name: Yup.string().required( "Template name is required!" ),
        description: Yup.string().required( "Template description is required!" ),
        formId: Yup.string().required( "Please select the form" )
    } )


    const createTemplate = async ( values, { resetForm } ) => {
        await ResumeBuilderStore.createTemplate( values )
        resetForm()
        setAddDialogState( false )
        navigate( `/admin/resume-templates/${ResumeBuilderStore.getTemplate.id}` )
    }

    useEffect( () => {
        const getData = async () => {
            await ResumeBuilderStore.fetchForms()
            setResumeForms( ResumeBuilderStore.getForms )
            setLoading( false )
        }
        getData()
    }, [ResumeBuilderStore] )

    return (
        <Dialog open={state}>
            <DialogTitle>Add new Template</DialogTitle>
            <DialogContent>
                <Formik onSubmit={createTemplate} initialValues={initialValues} validationSchema={validationSchema}>
                    {( { values, setFieldValue } ) => {
                        return ( <Form>
                            <FormControl fullWidth margin='normal'>
                                <Field as={TextField} name='name' label='Template name' />
                                <Typography color="error" fontSize="12px" variant="subtitle2"> <ErrorMessage name='name' /> </Typography>
                            </FormControl>
                            <FormControl fullWidth margin='normal'>
                                <Field as={TextField} multiline rows={4} name='description' label='Template description' />
                                <Typography color="error" fontSize="12px" variant="subtitle2"> <ErrorMessage name='description' /> </Typography>
                            </FormControl>
                            <FormControl fullWidth margin='normal'>
                                <InputLabel>Form</InputLabel>
                                <Select disabled={loading} label='Form' value={values.formId} onChange={e => { setFieldValue( 'formId', e.target.value ) }}>
                                    <MenuItem disabled sx={{ fontSize: "14px" }} dense value={''}>Select Resume Form</MenuItem>
                                    {resumeForms.map( form => (
                                        <MenuItem sx={{ fontSize: "14px" }} dense key={form.id} value={form.id}>{form.name}</MenuItem>
                                    ) )}
                                </Select>
                                <Typography color="error" fontSize="12px" variant="subtitle2"> <ErrorMessage name='formId' /> </Typography>
                            </FormControl>
                            <Box marginTop="20px" display="flex" gap="10px" alignItems="center" flexWrap="wrap">
                                <Button disableElevation variant="contained" type="submit" sx={{ textTransform: "capitalize" }}>Create Template</Button>
                                <Button color="error" onClick={() => setAddDialogState( false )} variant="outlined" sx={{ textTransform: "capitalize" }}>Cancel</Button>
                            </Box>
                        </Form> )
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    )
}

export default AddResumeTemplateDialog
