import lightTheme from "./light"
import darkTheme from './dark'


const themes = {
    light: lightTheme,
    dark: darkTheme,
}

export default themes

