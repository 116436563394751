import { observable, action, toJS, computed, makeObservable, runInAction } from 'mobx'
// import api from '../service/api'
// import NotificationStore from "./notification.store"
import React from 'react'
// const notification = NotificationStore

class UploadStatusList {
    dialogStatus
    attachmentList
    statusList

    constructor () {
        this.dialogStatus = false
        this.attachmentList = []
        this.statusList = []

        makeObservable( this, {
            dialogStatus: observable,
            attachmentList: observable,
            statusList: observable,
            getAttachmentList: computed,
            setAttachmentList: action,
            setStatusList: action,
            closeDialog: action,
            getDialogStatus: computed,
            openDialog: action
        } )
    }

    get getAttachmentList() {
        return toJS( this.attachmentList )
    }

    get getStatusList() {
        return toJS( this.statusList )
    }

    get getDialogStatus() {
        return this.dialogStatus
    }

    setAttachmentList( list ) {
        runInAction( () => {
            this.attachmentList = list
        } )
    }

    setStatusList( list ) {
        runInAction( () => {
            this.statusList = list
        } )
    }

    openDialog() {
        runInAction( () => {
            this.dialogStatus = true
        } )
    }

    closeDialog() {
        if ( this.dialogStatus ) {
            let newStatuses = [...this.statusList]
            let newAttachments = [...this.attachmentList]
            for ( let i = this.statusList.length - 1; i >= 0; i-- ) {
                const status = this.statusList[i]
                if ( status.statusText === "completed" || status.statusText === "failed" || !status.statusText ) {
                    newStatuses.splice( i, 1 )
                    newAttachments.splice( i, 1 )
                }
            }
            runInAction( () => {
                this.attachmentList = newAttachments
                this.statusList = newStatuses
            } )
        }
        runInAction( () => {
            this.dialogStatus = !this.dialogStatus
        } )
    }
}


const UploadStatusListInstance = new UploadStatusList()

export default UploadStatusListInstance

const UploadStatusListContext = React.createContext()

export const UploadStatusListProvider = ( { children, store } ) => {
    return (
        <UploadStatusListContext.Provider value={store}>{children}</UploadStatusListContext.Provider>
    )
}

export const useUploadStatusListStore = () => React.useContext( UploadStatusListContext )