import { observable, action, makeObservable, toJS, computed } from 'mobx'
// import api from '../service/api'
import UserStore from './user.store'
import React from 'react'


class ChatStore {
    chats
    conversation
    rooom

    constructor () {
        this.chats = [
            { name: "Sumit Singha Choudary", newMessages: true },
            { name: "Ektha Singh", newMessages: true },
            { name: "Ancy Cherian", newMessages: false },
            { name: "Manish Thakur", newMessages: false }
        ]

        this.conversation = [
            { id: 1, user_id: "ait19becs040", message: "Hi, ma'am", sent_date_time: new Date(), status: "read" },
            { id: 2, user_id: "emp040", message: "Hello, Rohith", sent_date_time: new Date(), status: "read" },
            { id: 3, user_id: "emp040", message: "How can I help you?", sent_date_time: new Date(), status: "read" },
            { id: 4, user_id: "ait19becs040", message: "I had some doubts about DS", sent_date_time: new Date(), status: "read" },
            { id: 5, user_id: "ait19becs040", message: "Particularly about Linked lists", sent_date_time: new Date(), status: "read" },
            { id: 6, user_id: "emp040", message: "Okay then, what it your doubts", sent_date_time: new Date(), status: "read" },
        ]

        this.faculties = [
            { name: "Sumit Singha Choudary" },
            { name: "Sanjay" },
            { name: "Sunil K S" },
            { name: "Pallavi S A" },
            { name: "Ektha Singh" },
            { name: "Ancy Cherian" },
            { name: "Manish Thakur" },
            { name: "Ranjan" }
        ]

        this.room = ""

        makeObservable( this, {
            getChats: computed,
            getConversation: computed,
            getRoom: computed,
            getFaculties: computed,
            setRoom: action,
            sendMessage: action,
            chats: observable,
            conversation: observable,
            room: observable
        } )
    }

    get getFaculties() {
        return toJS( this.faculties )
    }

    get getRoom() {
        return this.room
    }

    get getChats() {
        return toJS( this.chats )
    }

    get getConversation() {
        return toJS( this.conversation )
    }

    sendMessage( message ) {
        const newMessage = {}
        newMessage.id = this.conversation.length + 1
        newMessage.message = message
        newMessage.user_id = UserStore.getUser["user_id"]
        newMessage.sent_date_time = new Date()
        this.conversation.push( newMessage )
        console.log( this.conversation )
    }

    setRoom( id ) {
        this.room = id
    }
}


const ChatStoreInstance = new ChatStore()

export default ChatStoreInstance

const ChatStoreContext = React.createContext()

export const ChatStoreProvider = ( { children, store } ) => {
    return (
        <ChatStoreContext.Provider value={store}>{children}</ChatStoreContext.Provider>
    )
}

/* Hook to use store in any functional component */
export const useChatStore = () => React.useContext( ChatStoreContext )