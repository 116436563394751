import { Box, Typography } from '@mui/material'
import React from 'react'


const ResumeTemplatesList = ( { templates, setTemplatesDialogState, selectedTemplate, setSelectedTemplate } ) => {

    const handleClickOnTemplate = ( template ) => {
        setSelectedTemplate( template )
        setTemplatesDialogState( false )
    }

    return (
        <Box >
            <Box overflow='auto' display="flex" flexWrap="wrap" gap="20px">
                {templates.map( template => ( <Box key={template.id} onClick={() => handleClickOnTemplate( template )} sx={{ border: template.id === selectedTemplate.id ? "3px solid GrayText" : "3px solid #d3d3d3", background: template.id === selectedTemplate.id ? "#eee8" : "none", cursor: "pointer", "&:hover": { background: "#eee8", border: "3px solid GrayText" } }} display="flex" alignItems="center" justifyContent="center" width="100px" height="150px" borderRadius="2px" >
                    <Typography textAlign="center" variant="subtitle2">{template.name}</Typography>
                </Box> ) )}
            </Box>
        </Box>
    )
}

export default ResumeTemplatesList
